export default function validateEmail(values) {
  const validEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!validEmail.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  return errors;
}
