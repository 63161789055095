import { useContext } from 'react';
import _ from 'lodash';

import { Context } from './Context';

export interface Props {
  email: string;
  password: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | false>>;
}

export default function useLogin() {
  const { state, dispatch } = useContext(Context);

  return async function runLogin({ email, password, setErrorMessage }: Props) {
    dispatch({ type: 'CLEAR-STATE-AND-STORAGE' });
    setErrorMessage(false);
    try {
      await loginWithGoogle();
      const trimmedEmail = email.trim();
      dispatch({ type: 'setLoggedIn', data: true });
      const path = window.location.pathname;
      const isOnAccountPage = path.indexOf('account') !== -1;
      if (isOnAccountPage) return;
    } catch (err) {
      handleSetErrorMessage(err);
      throw err;
    }

    async function loginWithGoogle() {
      return state
        .firebaseAuth()
        .signInWithEmailAndPassword(email.trim(), password)
        .then(async ({ user }: { user: any }) => {
          const googleToken: string = await user.getIdToken();
          const googleId: string = user.uid;
          return { googleId, googleToken };
        })
        .catch(error => {
          throw error;
        });
    }

    function handleSetErrorMessage(err: any) {
      if (err.code && err.code === 'auth/user-not-found') {
        setErrorMessage('wrongEmail');
      }
      if (err.code && err.code === 'auth/wrong-password') {
        setErrorMessage('wrongPassword');
      }
    }
  };
}
