export default function initRecurlyJs() {
  const scriptIsOnPage = document.getElementById('recurly-script');
  if (!scriptIsOnPage) {
    const script = document.createElement('script');
    script.id = 'recurly-script';
    script.src = 'https://js.recurly.com/v4/recurly.js';
    script.async = true;
    document.body.appendChild(script);

    const link = document.createElement('link');
    link.href = 'https://js.recurly.com/v4/recurly.css';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.head.appendChild(link);
  }
}
