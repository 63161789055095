import styled from 'styled-components';

interface IF {
  open: boolean;
  right?: boolean | undefined;
}

interface IC {
  open: boolean;
  width?: string;
}

export const FullPageContainer = styled.div`
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.3);
  display: ${(props: IF) => (props.open ? 'flex' : 'none')};
  animation: fadein 0.3s ease-in-out;
  ${props => {
    if (props.right) {
      return `justify-content: flex-end;`;
    }
  }}
`;

export const AnimationContainer = styled.div`
  transition: width 0.3s ease-in-out;
  width: ${(props: IC) =>
    props.open ? (props.width ? props.width : '33.3vw') : 0};
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
`;
