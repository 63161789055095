export default function validateFormValues(values) {
  const validEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  const validPassword = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.{7,})');

  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!validEmail.test(values.email)) {
    errors.email = 'Please enter a valid email address';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (!validPassword.test(values.password)) {
    errors.password =
      'Password must contain a minimum of 7 characters and at least one number and one letter';
  }
  return errors;
}
