import React from 'react';

import { Provider } from './src/components/Context';
import SEO from './src/components/Seo';

export default function Wrapper({ element }) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider>
      <SEO />
      {element}
    </Provider>
  );
}
