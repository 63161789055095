import React from 'react';
import Layout from './Layout';
import SEO from './Seo';

export default function Layouts({ children }) {
  const questionnaireRegex = /questionnaire(?!-)/g;
  const isQuestionariePage = questionnaireRegex.test(children.props.path);

  return (
    <Layout isQuestionariePage={isQuestionariePage}>
      <SEO />
      {children}
    </Layout>
  );
}
