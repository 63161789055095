export function setOnLocalStorage(key: string, value: any) {
  const json = JSON.stringify(value);
  localStorage.setItem(key, json);
}

export function hydrateInitialState(initialState: any) {
  const newState = { ...initialState };
  Object.keys(newState).forEach(key => {
    let value = localStorage.getItem(key);

    if (value && value !== 'undefined') {
      try {
        value = JSON.parse(value);
        newState[key] = value;
      } catch (e) {
        console.log('err', e);
      }
    }
  });
  return newState;
}

export function clearLocalStorage(initialState: any, persistOnLocal: string[]) {
  Object.keys(initialState).forEach(key => {
    if (persistOnLocal.indexOf(key) === -1) {
      localStorage.removeItem(key);
    }
  });
}
