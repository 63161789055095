import React, { useContext, useState, MouseEvent } from 'react';
import styled from 'styled-components';

import { D } from '@dayetopia/library';
import validate from '../utils/validateEmail';
import { Context } from '../components/Context';
import IconWarning from '../images/icon-warning.svg';
import { navigate } from 'gatsby';

export default function ForgotPassword(props: {
  back?: () => void;
  style?: any;
  mobile?: boolean;
  inAccount?: boolean;
  hideForgotPassword?: () => void;
}) {
  const { back, style, mobile, inAccount, hideForgotPassword } = props;
  const { state } = useContext(Context);
  const [buttonText, setButtonText] = useState<any>('Send Link');
  const [showNoEmail, setShowNoEmail] = useState(false);
  const { values, errors, handleChange, handleSubmit } = D.useForm(
    handleForgotPassword,
    validate
  );

  async function handleForgotPassword() {
    setButtonText(<D.Spinner />);
    try {
      await state.firebaseAuth().sendPasswordResetEmail(values.email);
      setButtonText('Please check your email');
    } catch (err) {
      setButtonText('Send Link');
      setShowNoEmail(true);
    }
  }

  function handleBack() {
    if (back) return back();
    window.history.back();
  }

  function renderGoBack() {
    if (inAccount) {
      return (
        <D.P onClick={hideForgotPassword} mods={[14, 'link']} mobileMods={[13]}>
          Never mind, go back
        </D.P>
      );
    }
    function goCreateAccount() {
      navigate('/checkout/create-account');
    }
    return (
      <D.P onClick={handleBack} mods={[14, 'link']} mobileMods={[13]}>
        Never mind, I remember it
      </D.P>
    );
  }

  return (
    <Container style={style} mobile={mobile} onClick={e => e.stopPropagation()}>
      {!inAccount && (
        <>
          <D.H
            mods={[30, 'left']}
            mobileMods={[25]}
            style={{ marginBottom: 10 }}
          >
            Forgot your password?
          </D.H>
          <D.P mods={[16]} mobileMods={[13]} style={{ marginBottom: 20 }}>
            Don't worry, just enter your email address and we will send you a
            link via email.
          </D.P>
        </>
      )}
      {inAccount && (
        <PStyled mods={[14, 'caps', 'center', 'bold', 'spaced']}>
          Forgot password
        </PStyled>
      )}
      <form onSubmit={handleSubmit} noValidate={true}>
        <D.Input
          placeholder={'Your email'}
          heading={'Email'}
          name={'email'}
          onChange={handleChange}
          value={values.email || ''}
          errorMessage={errors.email}
          mobile={mobile || inAccount}
        />
        {showNoEmail && (
          <ErrorContainer>
            <IconWarningStyled />
            <D.P mods={['error']}>
              It looks like you don't have an account with us.{' '}
              <D.Span mods={['link']} onClick={goCreateAccount}>
                Create an account
              </D.Span>
            </D.P>
          </ErrorContainer>
        )}
        <MenuButton mobile={mobile || inAccount}>{buttonText}</MenuButton>
      </form>
      {renderGoBack()}
    </Container>
  );
}

interface IC {
  mobile: boolean | undefined;
  style: any;
  onClick: (e: MouseEvent) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: ${(props: IC) => (props.mobile ? '100%' : '380px')};
  animation: fadein 0.8s;
`;

const MenuButton = styled(D.Button)`
  width: 100%;
  margin-bottom: 20px;
`;

const ErrorContainer = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  background-color: #faead6;
  display: flex;
  align-content: center;
`;

const IconWarningStyled = styled(IconWarning)`
  margin-right: 10px;
  margin-top: 3px;
  flex-shrink: 0;
`;

const PStyled = styled(D.P)`
  margin-bottom: 32px;
  margin-top: -12px;
`;
