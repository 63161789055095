import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import IconDayeLogo from '../images/daye.svg';
import Curve from '../images/curve.png';
import { navigate } from 'gatsby';
import IconAccount from '../images/account.svg';
import { D } from '@dayetopia/library';
import NavLoginDrawer from './LoginDrawer';
import { Context } from './Context';

export default function NavStandard({ isQuestionariePage }) {
  const [animate, setAnimate] = useState(false);
  const [logInDrawerOpen, setLogInDrawerOpen] = useState(false);
  const { state } = useContext(Context);
  const { completion } = state;
  const width = isQuestionariePage ? `${completion * 100}%` : 0;

  const navColor = isQuestionariePage ? D.cream : '#c4e4c1';

  useEffect(() => {
    window.addEventListener('scroll', animateScroll);
    return () => {
      window.removeEventListener('scroll', animateScroll);
    };
  }, []);

  function animateScroll() {
    if (window.pageYOffset > 100) {
      setAnimate(true);
      return;
    }
    setAnimate(false);
  }

  return (
    <>
      <OuterContainer navColor={navColor} animate={animate}>
        <ProgressBar style={{ width }}>
          <CurveStyled src={Curve} />
        </ProgressBar>
        <Container>
          <DayeIcon
            onClick={() => {
              navigate('/');
            }}
          />

          <NavButtonsContainer>
            <AccountStyled
              active={false ? 1 : 0}
              onClick={() => setLogInDrawerOpen(true)}
              data-cy={'nav-account'}
            />
          </NavButtonsContainer>
        </Container>
      </OuterContainer>
      <NavLoginDrawer
        drawerOpen={logInDrawerOpen}
        setDrawerOpen={setLogInDrawerOpen}
      />
    </>
  );
}

interface OuterContainer {
  navColor: string;
  animate: boolean;
}

const OuterContainer = styled.div`
  position: fixed;
  top: 0;
  background-color: ${({ navColor }: OuterContainer) => navColor};
  width: 100vw;
  padding: 0;
  z-index: 1;
  animation: fadein 2s;
  box-shadow: none;
  transition: box-shadow 0.5s;
  ${({ animate }: Container) => {
    if (animate) {
      return `
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
      `;
    }
  }}
`;

const ProgressBar = styled.div`
  height: 100%;
  background: ${D.paleGold};
  position: absolute;
  transition: all 0.4s ease-out;
`;

const DayeIcon = styled(IconDayeLogo)`
  width: 130px;
  height: 100px;
  padding: 15px;
  cursor: pointer;
`;

interface Container {
  animate: boolean;
}

// const Container = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   position: fixed;
//   z-index: 1;
//   transition: background-color 0.4s ease;

//   ${({ animate }: Container) => {
//     if (animate) {
//       return `
//         background-color:  #c4e4c1;
//         box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
//       `;
//     }
//   }}
// `;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const NavButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 35px;
  width: 70px;
  @media (max-width: 768px) {
    width: 50px;
  }
`;
const CurveStyled = styled.img`
  position: absolute;
  right: -17px;
  height: 100%;
  @media (max-width: 500px) {
    right: -10px;
  }
`;
interface H1Styled {
  active?: boolean;
}

const H1Styled = styled(D.H1)`
  color: ${(props: H1Styled) => (props.active ? '#00391e' : 'default')};
  user-select: none;
`;

const AccountStyled = styled(IconAccount)`
  cursor: pointer;
  * {
    fill: ${D.darkGreen};
  }
  &:hover {
    * {
      fill: ${D.gold};
    }
  }
`;
