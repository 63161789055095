import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = () => {
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;
  return (
    <Helmet title={defaultTitle} titleTemplate={titleTemplate}>
      <title>{defaultTitle}</title>
      <meta name="description" content={defaultDescription} />
      <meta name="image" content={defaultImage} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={defaultTitle} />
      <meta property="og:description" content={defaultDescription} />
      <link rel="canonical" href="https://yourdaye.com/" />
      <meta property="og:image" content={defaultImage} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="twitter:creator" content={twitterUsername} />

      <meta name="twitter:title" content={defaultTitle} />

      <meta name="twitter:description" content={defaultDescription} />

      <meta name="twitter:image" content={defaultImage} />
    </Helmet>
  );
};
export default SEO;
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  url: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultImage: image
        siteUrl: siteUrl
        twitterUsername
      }
    }
  }
`;
