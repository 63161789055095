import React from 'react';
import styled from 'styled-components';

import '../sass/main.scss';
import { D } from '@dayetopia/library';
import Nav from './NavStandard';
import favicon from '../images/favicon_small.png';

interface Props {
  children: any;
  isQuestionariePage: boolean;
}

export default function Layout({ children, isQuestionariePage }: Props) {
  return (
    <LayoutWrapper>
      <Nav isQuestionariePage={isQuestionariePage} />
      {/* because the nav is fixed we need the dummy div to make sure everything
      comes under it */}
      <DummyNavDiv />
      {children}
    </LayoutWrapper>
  );
}

const LayoutWrapper = styled.div`
  min-height: 100vh;
  animation: fadeIn 0.8s;
  background: ${D.paleGreen};
`;

const DummyNavDiv = styled.div`
  height: 100px;
  width: 100%;
`;
