// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-home-page-tsx": () => import("./../../../src/components/HomePage.tsx" /* webpackChunkName: "component---src-components-home-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-checking-history-tsx": () => import("./../../../src/pages/checking-history.tsx" /* webpackChunkName: "component---src-pages-checking-history-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-get-results-tsx": () => import("./../../../src/pages/get-results.tsx" /* webpackChunkName: "component---src-pages-get-results-tsx" */),
  "component---src-pages-oldindexold-tsx": () => import("./../../../src/pages/oldindexold.tsx" /* webpackChunkName: "component---src-pages-oldindexold-tsx" */),
  "component---src-pages-order-success-tsx": () => import("./../../../src/pages/order-success.tsx" /* webpackChunkName: "component---src-pages-order-success-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-questionnaire-landing-tsx": () => import("./../../../src/pages/questionnaire-landing.tsx" /* webpackChunkName: "component---src-pages-questionnaire-landing-tsx" */),
  "component---src-pages-questionnaire-tsx": () => import("./../../../src/pages/questionnaire.tsx" /* webpackChunkName: "component---src-pages-questionnaire-tsx" */),
  "component---src-pages-questionnaire-warning-tsx": () => import("./../../../src/pages/questionnaire-warning.tsx" /* webpackChunkName: "component---src-pages-questionnaire-warning-tsx" */),
  "component---src-pages-recommendation-tsx": () => import("./../../../src/pages/recommendation.tsx" /* webpackChunkName: "component---src-pages-recommendation-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

