import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { D } from '@dayetopia/library';
import { Context } from './Context';
import { FullPageContainer, AnimationContainer } from './NavAnimation';
import useLogin from './useLogin';
import validate from '../utils/validateFormValues';
import ForgotPassword from './ForgotPassword';
import IconClose from '../images/cross.svg';

export default function NavLoginDrawer({
  drawerOpen,
  setDrawerOpen,
}: {
  drawerOpen: boolean;
  setDrawerOpen: (a: boolean) => void;
}) {
  const { state, dispatch } = useContext(Context);
  const { loggedIn } = state;
  const [showContainer, setShowContainer] = useState(false);
  const [animateIn, setAnimateIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | false>(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const runLogin = useLogin();
  const mobile = window.innerWidth < 600;
  const { values, errors, handleChange, handleSubmit } = D.useForm(
    handleLogin,
    validate
  );
  function handleCloseNav() {
    setDrawerOpen(false);
  }

  async function handleLogin() {
    dispatch({ type: 'CLEAR-STATE-AND-STORAGE' });
    setLoading(true);
    const { email, password } = values;
    try {
      await runLogin({
        email,
        password,
        setErrorMessage,
      });
    } catch (err) {
      setLoading(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (drawerOpen) {
      setShowContainer(true);
      setTimeout(() => {
        setAnimateIn(true);
      }, 200);
    } else {
      setAnimateIn(false);
      setTimeout(() => {
        setShowContainer(false);
      }, 200);
    }
  }, [drawerOpen]);

  function handleLogOut() {
    state.firebaseAuth().signOut();
    navigate('/');
  }

  function handleNavAccount() {
    setDrawerOpen(false);
    navigate('/account');
  }

  if (loggedIn) {
    return (
      <FullPageContainer
        right={true}
        open={showContainer}
        onClick={handleCloseNav}
      >
        <AnimationContainer open={animateIn} width={mobile ? '100%' : '33.3vw'}>
          <Container mobile={mobile} onClick={e => e.stopPropagation()}>
            <IconCloseStyled onClick={() => setDrawerOpen(false)} />
            <div>
              <D.P style={{ marginBottom: 40 }}>Welcome back to Daye</D.P>
              <H4Styled onClick={handleNavAccount} style={{ marginBottom: 40 }}>
                My account
              </H4Styled>
            </div>
            <D.P mods={[14, 'link']} onClick={handleLogOut}>
              Log out
            </D.P>
          </Container>
        </AnimationContainer>
      </FullPageContainer>
    );
  }

  function renderForm() {
    if (passwordReset) {
      return (
        <ForgotPassword
          back={() => setPasswordReset(false)}
          style={{ width: '100%', margin: 0 }}
          mobile={true}
        />
      );
    }

    return (
      <div id={'reset-font-size'}>
        <HStyled mods={[26]} data-cy="account-menu-title">
          Log in to your account
        </HStyled>
        <PStyled mods={[13]}>
          Don’t have an account yet?{' '}
          <a href="/checkout/create-account">
            <D.Span mods={['link']}>Create one</D.Span>
          </a>
        </PStyled>
        <form onSubmit={handleSubmit} noValidate={true}>
          <D.Input
            placeholder={'Email Address'}
            heading={'Your Email'}
            name={'email'}
            onChange={handleChange}
            value={values.email || ''}
            errorMessage={errors.email}
            mobile={true}
            autocorrect="off"
            autocapitalize="none"
          />
          <D.Input
            placeholder={'Password'}
            heading={'Your password'}
            type={'password'}
            name={'password'}
            onChange={handleChange}
            value={values.password || ''}
            errorMessage={errors.password}
            mobile={true}
          />
          <PStyled
            onClick={() => setPasswordReset(true)}
            mods={[14, 'right', 'link']}
          >
            Forgot your password?
          </PStyled>
          <FlexWrapper>
            <MenuButton>{loading ? <D.Spinner /> : 'Log in'}</MenuButton>
          </FlexWrapper>
        </form>
        <D.ErrorMessage errorMessage={errorMessage} />
      </div>
    );
  }

  return (
    <FullPageContainer
      right={true}
      open={showContainer}
      onClick={handleCloseNav}
    >
      <AnimationContainer open={animateIn} width={mobile ? '100%' : '33.3vw'}>
        <Container mobile={mobile} onClick={e => e.stopPropagation()}>
          <IconCloseStyled onClick={() => setDrawerOpen(false)} />

          {renderForm()}
        </Container>
      </AnimationContainer>
    </FullPageContainer>
  );
}

interface IP {
  mobile: boolean | undefined;
  onClick: (e: any) => any;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${D.paleGreen};
  padding: 260px 40px 40px 40px;
  padding-top: 160px;
  width: ${(props: IP) => (props.mobile ? '100vw' : '33.3vw')};
  height: 100%;
  @media only screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;
  }
`;

const HStyled = styled(D.H)`
  margin-bottom: 10px;
`;

const PStyled = styled(D.P)`
  margin-bottom: 30px;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const MenuButton = styled(D.Button)`
  width: 100%;
  height: 46px;
`;

const H4Styled = styled(D.H4)`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const IconCloseStyled = styled(IconClose)`
  position: absolute;
  top: 30px;
  right: 30px;
  &:hover {
    cursor: pointer;
    * {
      fill: #eda91f;
    }
  }
`;
