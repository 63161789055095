import React, { useReducer, useEffect } from 'react';

import {
  setOnLocalStorage,
  hydrateInitialState,
  clearLocalStorage,
} from '../utils/localStorage';
import keys from '../../keys.json';
import * as TODO from '../types';
import * as T from 'daye-types';

interface ISetEmail {
  type: 'setEmail';
  data: string;
}

interface ISetPassword {
  type: 'setPassword';
  data: string;
}

interface ISetAccountEmail {
  type: 'setAccountEmail';
  data: string;
}

interface ISetAccountFirstName {
  type: 'setAccountFirstName';
  data: string;
}

interface ISetAccountLastName {
  type: 'setAccountLastName';
  data: string;
}

interface ISetMarketing {
  type: 'setMarketing';
  data: boolean;
}

interface ISetEditShippingAddress {
  type: 'setEditShippingAddress';
  data: boolean;
}

interface ISetDiscountExpiryTime {
  type: 'setDiscountExpiryTime';
  data: number;
}

interface ISetDiscountAmount {
  type: 'setDiscountAmount';
  data: number;
}

interface ISetCheckoutDiscountAmount {
  type: 'setCheckoutDiscountAmount';
  data: number;
}

interface ISetLatestOrder {
  type: 'setLatestOrder';
  data: any;
}

interface ISetNextBillingDate {
  type: 'setNextBillingDate';
  data: string;
}

interface ISetPlanLength {
  type: 'setPlanLength';
  data: any;
}

interface ISetFuturePlanLength {
  type: 'setFuturePlanLength';
  data: any;
}

interface ISetSubscriptionExpired {
  type: 'setSubscriptionExpired';
  data: boolean;
}

interface ISetNoSubscriptionEver {
  type: 'setNoSubscriptionEver';
  data: boolean;
}

interface ISetSubscriptionId {
  type: 'setSubscriptionId';
  data: string;
}

interface ISetFirebaseAuth {
  type: 'setFirebaseAuth';
  data: any;
}

interface ISetFirebaseDb {
  type: 'setFirebaseDb';
  data: any;
}

interface ISetLoggedIn {
  type: 'setLoggedIn';
  data: boolean;
}

interface ISetFirebaseLoaded {
  type: 'setFirebaseLoaded';
  data: boolean;
}

interface ISetDesktop {
  type: 'setDesktop';
  data: boolean;
}

interface IRehydrateFromLocalStorage {
  type: 'REHYDRATE-FROM-LOCAL-STORAGE';
  data: State;
}

interface IClearStateLocalStorage {
  type: 'CLEAR-STATE-AND-STORAGE';
}

interface ISetSelectedPlan {
  type: 'setSelectedPlan';
  data: string | undefined;
}

interface ISetShowStatusBar {
  type: 'setShowStatusBar';
  data: boolean;
}
interface ISetStatusMessage {
  type: 'setStatusMessage';
  data: string;
}

interface SetRecommendation {
  type: 'setRecommendation';
  data: TODO.RecommendationsFromOMS;
}

interface SelectedBox {
  type: 'setSelectedBox';
  data: any;
}

interface ISetQuestionAnswers {
  type: 'setQuestionAnswers';
  data: any;
}

interface SetQuestionnaireCompletion {
  type: 'setQuestionnaireCompletion';
  data: number;
}

interface SetQuestionnaireStatus {
  type: 'setQuestionnaireStatus';
  data: string;
}

type ActionTypes =
  | ISetEmail
  | ISetPassword
  | ISetAccountEmail
  | ISetAccountFirstName
  | ISetAccountLastName
  | ISetMarketing
  | ISetEditShippingAddress
  | ISetDiscountExpiryTime
  | ISetDiscountAmount
  | ISetCheckoutDiscountAmount
  | ISetLatestOrder
  | ISetNextBillingDate
  | ISetPlanLength
  | ISetFuturePlanLength
  | ISetSubscriptionExpired
  | ISetNoSubscriptionEver
  | ISetSubscriptionId
  | ISetFirebaseAuth
  | ISetFirebaseDb
  | ISetLoggedIn
  | ISetFirebaseLoaded
  | ISetDesktop
  | IRehydrateFromLocalStorage
  | IClearStateLocalStorage
  | ISetSelectedPlan
  | ISetShowStatusBar
  | ISetStatusMessage
  | SetRecommendation
  | SelectedBox
  | ISetQuestionAnswers
  | SetQuestionnaireCompletion
  | SetQuestionnaireStatus;

export const initialState: State = {
  email: '',
  password: '',
  accountEmail: '',
  accountFirstName: '',
  accountLastName: '',
  marketing: false,
  editShippingAddress: true,
  discountExpiryTime: undefined,
  discountAmount: undefined,
  checkoutDiscountAmount: undefined,
  latestOrder: undefined,
  nextBillingDate: undefined,
  planLength: '',
  futurePlanLength: '',
  subscriptionExpired: false,
  noSubscriptionEver: false,
  subscriptionId: '',
  firebaseAuth: undefined,
  firebaseDb: undefined,
  loggedIn: false,
  firebaseLoaded: false,
  desktop: true,
  selectedPlan: 'contraception',
  showStatusBar: false,
  statusMessage: '',
  recommendation: {},
  selectedBox: undefined,
  questionAnswers: undefined,
  completion: 0,
  questionnaireStatus: '',
  // recommendedBox: undefined,
  // nextBoxPrice: undefined,
  // nextBoxVariants: undefined,
};

export interface IContext {
  state: State;
  dispatch(a: ActionTypes): void;
}

const mockDispatch = (a: ActionTypes) => {
  console.log(a);
};

const initial: IContext = {
  state: initialState,
  dispatch: mockDispatch,
};

const Context = React.createContext(initial);

function reducer(state: State, action: ActionTypes): State {
  switch (action.type) {
    case 'setEmail':
      return {
        ...state,
        email: action.data,
      };
    case 'setPassword':
      return {
        ...state,
        password: action.data,
      };
    case 'setAccountEmail':
      setOnLocalStorage('accountEmail', action.data);
      return {
        ...state,
        accountEmail: action.data,
      };
    case 'setAccountFirstName':
      setOnLocalStorage('accountFirstName', action.data);
      return {
        ...state,
        accountFirstName: action.data,
      };
    case 'setAccountLastName':
      setOnLocalStorage('accountLastName', action.data);
      return {
        ...state,
        accountLastName: action.data,
      };

    case 'setMarketing':
      setOnLocalStorage('marketing', action.data);
      return {
        ...state,
        marketing: action.data,
      };
    case 'setEditShippingAddress':
      setOnLocalStorage('editShippingAddress', action.data);
      return {
        ...state,
        editShippingAddress: action.data,
      };
    case 'setDiscountExpiryTime':
      setOnLocalStorage('discountExpiryTime', action.data);
      return {
        ...state,
        discountExpiryTime: action.data,
      };
    case 'setDiscountAmount':
      setOnLocalStorage('discountAmount', action.data);
      return {
        ...state,
        discountAmount: action.data,
      };
    case 'setCheckoutDiscountAmount':
      return {
        ...state,
        checkoutDiscountAmount: action.data,
      };
    case 'setLatestOrder':
      setOnLocalStorage('latestOrder', action.data);
      return {
        ...state,
        latestOrder: action.data,
      };
    case 'setNextBillingDate':
      setOnLocalStorage('nextBillingDate', action.data);
      return {
        ...state,
        nextBillingDate: action.data,
      };

    case 'setPlanLength':
      setOnLocalStorage('planLength', action.data);
      return {
        ...state,
        planLength: action.data,
      };
    case 'setFuturePlanLength':
      setOnLocalStorage('planLength', action.data);
      return {
        ...state,
        futurePlanLength: action.data,
      };
    case 'setSubscriptionExpired':
      setOnLocalStorage('subscriptionExpired', action.data);
      return {
        ...state,
        subscriptionExpired: action.data,
      };

    case 'setNoSubscriptionEver':
      setOnLocalStorage('noSubscriptionEver', action.data);
      return {
        ...state,
        noSubscriptionEver: action.data,
      };
    case 'setSubscriptionId':
      setOnLocalStorage('subscriptionId', action.data);
      return {
        ...state,
        subscriptionId: action.data,
      };
    case 'setFirebaseAuth':
      return {
        ...state,
        firebaseAuth: action.data,
      };
    case 'setFirebaseDb':
      return {
        ...state,
        firebaseDb: action.data,
      };
    case 'setFirebaseLoaded':
      return {
        ...state,
        firebaseLoaded: action.data,
      };
    case 'setLoggedIn':
      setOnLocalStorage('loggedIn', action.data);
      return {
        ...state,
        loggedIn: action.data,
      };
    case 'setDesktop':
      return {
        ...state,
        desktop: action.data,
      };
    case 'setSelectedPlan':
      setOnLocalStorage('selectedPlan', action.data);
      return {
        ...state,
        selectedPlan: action.data,
      };
    case 'setShowStatusBar':
      return {
        ...state,
        showStatusBar: action.data,
      };
    case 'setStatusMessage':
      return {
        ...state,
        statusMessage: action.data,
      };
    case 'REHYDRATE-FROM-LOCAL-STORAGE':
      return {
        ...action.data,
      };
    case 'setRecommendation':
      setOnLocalStorage('recommendation', action.data);
      return {
        ...state,
        recommendation: action.data,
      };
    case 'setSelectedBox':
      setOnLocalStorage('selectedBox', action.data);
      return {
        ...state,
        selectedBox: action.data,
      };
    case 'setQuestionAnswers':
      setOnLocalStorage('questionAnswers', action.data);
      return {
        ...state,
        questionAnswers: action.data,
      };
    case 'setQuestionnaireCompletion':
      return {
        ...state,
        completion: action.data,
      };
    case 'setQuestionnaireStatus':
      return {
        ...state,
        questionnaireStatus: action.data,
      };
    case 'CLEAR-STATE-AND-STORAGE':
      const persistOnLocal = [
        'firebaseAuth',
        'firebaseDb',
        'firebaseLoaded',
        'recommendation',
        'selectedBox',
        'questionAnswers'
      ];
      clearLocalStorage(initialState, persistOnLocal);
      // clear possible old user but keep state of login/create account page
      return {
        ...initialState,
        email: state.email,
        password: state.password,
        marketing: state.marketing,
        firebaseAuth: state.firebaseAuth,
        firebaseDb: state.firebaseDb,
        firebaseLoaded: state.firebaseLoaded,
        recommendation: state.recommendation,
        selectedBox: state.selectedBox,
        questionAnswers: state.questionAnswers,
      };
    default:
      throw new Error(JSON.stringify(action, null, 2));
  }
}

function Provider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    rehydrateFromLocalStorage();
  }, []);

  function rehydrateFromLocalStorage() {
    const initialStateWithLocalStorage: State = hydrateInitialState(
      initialState
    );
    dispatch({
      type: 'REHYDRATE-FROM-LOCAL-STORAGE',
      data: initialStateWithLocalStorage,
    });
  }

  useEffect(() => {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const database = import('firebase/firestore');

    Promise.all([app, auth, database]).then(async firebase => {
      if (!firebase[0].apps.length) {
        firebase[0].initializeApp({
          apiKey: keys.googleApiKey,
          authDomain: keys.googleAuthDomain,
          projectId: keys.googleProjectId,
        });
      }
      const fb = firebase[0];
      const fbAuth = fb.auth;
      dispatch({ type: 'setFirebaseAuth', data: fbAuth });
      dispatch({ type: 'setFirebaseDb', data: fb.firestore() });
    });
  }, []);

  useEffect(() => {
    if (!state.firebaseAuth) return;
    state.firebaseAuth().onAuthStateChanged((user: any) => {
      if (user) {
        dispatch({ type: 'setLoggedIn', data: true });
      } else {
        dispatch({ type: 'setLoggedIn', data: false });
        // remove recurly data on firebaseAuth().signOut
        dispatch({ type: 'CLEAR-STATE-AND-STORAGE' });
      }
      dispatch({ type: 'setFirebaseLoaded', data: true });
    });
  }, [state.firebaseAuth]);

  function showOnDesktop() {
    if (window.innerWidth > 768) {
      dispatch({ type: 'setDesktop', data: true });
    } else {
      dispatch({ type: 'setDesktop', data: false });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      showOnDesktop();
    }, 400);
    window.addEventListener('resize', showOnDesktop);

    return () => {
      window.removeEventListener('resize', showOnDesktop);
    };
  }, []);

  useEffect(() => {
    if (window.Cypress) {
      window.appContext = state;
    }
  });

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {state.firebaseLoaded && props.children}
    </Context.Provider>
  );
}

export interface State {
  email: string;
  password: string;
  accountEmail: string;
  accountFirstName: string;
  accountLastName: string;
  marketing: boolean;
  editShippingAddress: boolean;
  discountExpiryTime: number | undefined;
  discountAmount: number | undefined;
  checkoutDiscountAmount: number | undefined;
  latestOrder: any;
  nextBillingDate: undefined | string;
  planLength: string;
  futurePlanLength: string;
  subscriptionExpired: boolean;
  noSubscriptionEver: boolean;
  subscriptionId: string;
  firebaseAuth: any;
  firebaseDb: any;
  loggedIn: boolean;
  firebaseLoaded: boolean;
  desktop: boolean;
  selectedPlan: string;
  showStatusBar: boolean;
  statusMessage: string;
  recommendation: TODO.RecommendationsFromOMS | {};
  selectedBox: TODO.Recommendation | undefined;
  questionAnswers: string | undefined;
  completion: number;
  questionnaireStatus: string | undefined;
}

export { Context, Provider };
